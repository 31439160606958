import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { DropdownMenuItem as RadixMenuItem, DropdownMenuItemProps as RadixMenuItemProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixMenuItem };

// Re export types
export type { RadixMenuItemProps };

// Types
export interface MenuItemSharedProps {
  MenuItemComponent?: React.FC<MenuItemProps> | React.FC<any>;
  StartAdornment?: React.FC<any>;
  EndAdornment?: React.FC<any>;
  menuItemAdornmentClass?: string;
  menuItemStartAdornmentClass?: string;
  menuItemEndAdornmentClass?: string;
  menuItemClass?: string;
}
export interface MenuItemSpecificProps extends MenuItemSharedProps {
  clsPrefix?: string;
  active?: boolean;
  content?: React.ReactNode;
}
export interface MenuItemProps extends Omit<RadixMenuItemProps, 'content'>, MenuItemSpecificProps {}
const AdornmentStub = () => <></>;
export const MenuItem = ({
  MenuItemComponent = RadixMenuItem,
  StartAdornment = AdornmentStub,
  EndAdornment = AdornmentStub,
  menuItemAdornmentClass = '',
  menuItemStartAdornmentClass = '',
  menuItemEndAdornmentClass = '',
  menuItemClass,
  className = menuItemClass,
  clsPrefix = 'radix-dropdown',
  children,
  active = false,
  ...props
}: MenuItemProps) => {
  return <MenuItemComponent className={`${s.menuItem} ${clsPrefix}-item ${className}`} data-active={active} {...props}>
      <StartAdornment className={`${clsPrefix}-item-adornment ${clsPrefix}-item-start-adornment ${menuItemAdornmentClass} ${menuItemStartAdornmentClass}`} />
      {children}
      <EndAdornment className={`${clsPrefix}-item-adornment ${clsPrefix}-item-end-adornment ${menuItemAdornmentClass} ${menuItemEndAdornmentClass}`} />
    </MenuItemComponent>;
};
export default MenuItem;