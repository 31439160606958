import React from 'react';

// Components
import { Root, RootSharedProps, RootProps } from './components/Root';
import { Trigger, TriggerSharedProps } from './components/Trigger';
import { Content, ContentSharedProps } from './components/Content';
import { Portal, PortalSharedProps } from './components/Portal';
import { Arrow, ArrowSharedProps } from './components/Arrow';
import { ComboMenuItemSharedProps } from './components/ComboMenuItem';
import { ComboMenuItems, ComboMenuItemsProps, ComboMenuItemsSharedProps } from './components/ComboMenuItems';

// Types
import { SubSharedProps } from './components/Sub';
import { SubTriggerSharedProps } from './components/SubTrigger';
import { SubContentSharedProps } from './components/SubContent';
import { MenuItemSharedProps } from './components/MenuItem';
import { GroupSharedProps } from './components/Group';
import { RadioGroupSharedProps } from './components/RadioGroup';

// Re export components and types
export * from './components/Arrow';
export * from './components/CheckboxItem';
export * from './components/CheckIcon';
export * from './components/ComboMenuItem';
export * from './components/ComboMenuItems';
export * from './components/Content';
export * from './components/DotIcon';
export * from './components/Group';
export * from './components/Label';
export * from './components/MenuItem';
export * from './components/Portal';
export * from './components/Sub';
export * from './components/SubPortal';
export * from './components/RadioGroup';
export * from './components/RadioItem';
export * from './components/Root';
export * from './components/Separator';
export * from './components/SubContent';
export * from './components/SubTrigger';
export * from './components/Trigger';
export * from './components/ItemIndicator';
export interface DropdownMenuSpecificProps extends RootSharedProps, TriggerSharedProps, ContentSharedProps, PortalSharedProps, ArrowSharedProps, SubSharedProps, SubTriggerSharedProps, SubContentSharedProps, ComboMenuItemSharedProps, MenuItemSharedProps, GroupSharedProps, RadioGroupSharedProps, ComboMenuItemsSharedProps {
  asChild?: boolean;
  clsPrefix?: string;
  className?: string;
  content?: React.ReactNode;
  bottomContent?: React.ReactNode;
  disabled?: boolean;
  disableItems?: boolean;
}
export interface DropdownMenuProps extends RootProps, DropdownMenuSpecificProps {
  ComboMenuItemsComponent?: React.FC<ComboMenuItemsProps>;
}
export const DropdownMenu = ({
  RootComponent = Root,
  TriggerComponent = Trigger,
  PortalComponent = Portal,
  ContentComponent = Content,
  ArrowComponent = Arrow,
  ComboMenuItemsComponent = ComboMenuItems,
  ComboMenuItemWrapComponent,
  ComboMenuItemComponent,
  ComboMenuItemsWrapComponent,
  MenuItemComponent,
  LabelComponent,
  GroupComponent,
  CheckboxItemComponent,
  ItemIndicatorComponent,
  RadioGroupComponent,
  RadioItemComponent,
  SeparatorComponent,
  SubComponent,
  SubContentComponent,
  SubTriggerComponent,
  SubPortalComponent,
  CheckIconComponent,
  DotIconComponent,
  StartAdornment,
  EndAdornment,
  arrowClass,
  groupClass,
  labelClass,
  dotIconClass,
  triggerClass,
  contentClass,
  menuItemClass,
  radioItemClass,
  separatorClass,
  checkIconClass,
  subContentClass,
  radioGroupClass,
  subTriggerClass,
  checkboxItemClass,
  itemIndicatorClass,
  menuItemAdornmentClass,
  menuItemEndAdornmentClass,
  menuItemStartAdornmentClass,
  clsPrefix = 'radix-dropdown',
  className = triggerClass,
  items = [],
  children,
  content = '',
  bottomContent = '',
  disabled = false,
  disableItems = false,
  asChild,
  onAnyItemClick,
  onItemClick,
  onLabelClick,
  onCheckboxClick,
  onRadioClick,
  onSeparatorClick,
  ...props
}: DropdownMenuProps) => {
  return <RootComponent {...props}>
      <TriggerComponent className={className} {...{
      disabled,
      clsPrefix,
      asChild
    }}>
        {children}
      </TriggerComponent>

      <PortalComponent>
        <ContentComponent {...{
        clsPrefix,
        contentClass
      }}>
          {content}
          {!!items?.length && <ComboMenuItemsComponent {...{
          ComboMenuItemsWrapComponent,
          ComboMenuItemWrapComponent,
          ComboMenuItemComponent,
          GroupComponent,
          RadioGroupComponent,
          SubComponent,
          SubContentComponent,
          SubTriggerComponent,
          PortalComponent,
          SubPortalComponent,
          LabelComponent,
          MenuItemComponent,
          CheckboxItemComponent,
          RadioItemComponent,
          SeparatorComponent,
          ItemIndicatorComponent,
          CheckIconComponent,
          DotIconComponent,
          StartAdornment,
          EndAdornment,
          groupClass,
          labelClass,
          dotIconClass,
          triggerClass,
          contentClass,
          menuItemClass,
          radioItemClass,
          separatorClass,
          checkIconClass,
          subContentClass,
          radioGroupClass,
          subTriggerClass,
          checkboxItemClass,
          itemIndicatorClass,
          menuItemAdornmentClass,
          menuItemEndAdornmentClass,
          menuItemStartAdornmentClass,
          items,
          disableItems,
          clsPrefix,
          onAnyItemClick,
          onItemClick,
          onLabelClick,
          onCheckboxClick,
          onRadioClick,
          onSeparatorClick
        }} />}
          {bottomContent}
          <ArrowComponent {...{
          clsPrefix,
          arrowClass
        }} />
        </ContentComponent>
      </PortalComponent>
    </RootComponent>;
};
export default DropdownMenu;