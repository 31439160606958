import { styled, css } from 'styled-components';

// Components
import { MenuItem, MenuItemProps } from '@ui/radix-dropdown';

// Types
export interface StyledMenuItemProps extends MenuItemProps {}

// Styled components
export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>`
  ${({
  theme
}) => css`
    color: ${theme?.['color']?.dark400};
    font-size: ${theme?.['fontSize']?.m};
    border-radius: ${theme?.['borderRadius']?.m};
    padding: 10px 12px;

    &:hover,
    &[data-active='true'] {
      color: ${theme?.['color']?.dark500};
      background-color: ${theme?.['color']?.grey50};
    }

    &[data-disabled] {
      opacity: 0.5;
    }

    .radix-dropdown-item-start-adornment {
      width: 20px;
      max-height: 20px;
      margin-right: 14px;
    }
  `};
`;