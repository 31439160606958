import React from 'react';

// Components
import { Label, LabelProps, LabelSharedProps } from '../Label';
import { MenuItem, MenuItemProps, MenuItemSharedProps } from '../MenuItem';
import { RadioItem, RadioItemProps, RadioItemSharedProps } from '../RadioItem';
import { CheckboxItem, CheckboxItemProps, CheckboxItemSharedProps } from '../CheckboxItem';
import { Separator, SeparatorProps, SeparatorSharedProps } from '../Separator';

// Utils
import { assignNonUndefined } from '@tools/ts-assign-non-undefined';

// Types
import type { GroupProps } from '../Group';
import type { RadioGroupProps } from '../RadioGroup';
export type ComboMenuItemSharedProps = LabelSharedProps & MenuItemSharedProps & RadioItemSharedProps & CheckboxItemSharedProps & SeparatorSharedProps & {
  ComboMenuItemWrapComponent?: React.FC<ComboMenuItemProps> | React.FC<any>;
  onAnyItemClick?: (e: React.MouseEvent, item: ComboMenuItemProps) => void;
  onItemClick?: (e: React.MouseEvent, item: ComboMenuItemProps) => void;
  onLabelClick?: (e: React.MouseEvent, item: ComboMenuItemProps) => void;
  onCheckboxClick?: (e: React.MouseEvent, item: ComboMenuItemProps) => void;
  onRadioClick?: (e: React.MouseEvent, item: ComboMenuItemProps) => void;
  onSeparatorClick?: (e: React.MouseEvent, item: ComboMenuItemProps) => void;
};
export type ComboMenuItemSpecificProps = ComboMenuItemSharedProps & {
  id?: string | number | any;
  type?: 'sub' | 'item' | 'label' | 'radio' | 'group' | 'checkbox' | 'radio-group' | 'separator';
  content?: React.ReactNode;
  items?: ComboMenuItemProps[];
  clsPrefix?: string;
};
export type ComboMenuItemProps = (Partial<LabelProps> | Partial<GroupProps> | Partial<MenuItemProps> | Partial<RadioItemProps> | Partial<SeparatorProps> | Partial<RadioGroupProps> | Partial<CheckboxItemProps>) & ComboMenuItemSpecificProps;
const Stub = (p: any) => <>{p.children}</>;
export const ComboMenuItem = (props: ComboMenuItemProps) => {
  const {
    children,
    EndAdornment,
    content = '',
    type = 'item',
    StartAdornment,
    DotIconComponent,
    CheckIconComponent,
    LabelComponent = Label,
    ItemIndicatorComponent,
    MenuItemComponent = MenuItem,
    clsPrefix = 'radix-dropdown',
    RadioItemComponent = RadioItem,
    SeparatorComponent = Separator,
    ComboMenuItemWrapComponent = Stub,
    CheckboxItemComponent = CheckboxItem,
    onClick,
    onItemClick,
    onLabelClick,
    onRadioClick,
    onAnyItemClick,
    onCheckboxClick,
    onSeparatorClick,
    ...otherProps
  } = props;
  let Component: React.FC<LabelProps> | React.FC<MenuItemProps> | React.FC<RadioItemProps> | React.FC<SeparatorProps> | React.FC<CheckboxItemProps> | React.FC<any> = MenuItem;
  const componentProps: ComboMenuItemProps = {
    clsPrefix,
    ...otherProps
  };
  switch (type) {
    case 'label':
      Component = LabelComponent;

      // Label click event
      componentProps.onClick = (e: React.MouseEvent) => {
        onClick?.(e as React.MouseEvent<HTMLDivElement>);
        onAnyItemClick?.(e, props);
        onLabelClick?.(e, props);
      };
      break;
    case 'checkbox':
      Component = RadioItemComponent;

      // Special props for CheckboxItem
      assignNonUndefined(componentProps, {
        ItemIndicatorComponent,
        DotIconComponent
      });

      // Checkbox click event
      componentProps.onClick = (e: React.MouseEvent) => {
        onClick?.(e as React.MouseEvent<HTMLDivElement>);
        onAnyItemClick?.(e, props);
        onCheckboxClick?.(e, props);
      };
      break;
    case 'radio':
      Component = CheckboxItemComponent;

      // Special props for RadioItem
      assignNonUndefined(componentProps, {
        ItemIndicatorComponent,
        CheckIconComponent
      });

      // Radio click event
      componentProps.onClick = (e: React.MouseEvent) => {
        onClick?.(e as React.MouseEvent<HTMLDivElement>);
        onAnyItemClick?.(e, props);
        onRadioClick?.(e, props);
      };
      break;
    case 'separator':
      Component = SeparatorComponent;

      // Separator click event
      componentProps.onClick = (e: React.MouseEvent) => {
        onClick?.(e as React.MouseEvent<HTMLDivElement>);
        onAnyItemClick?.(e, props);
        onSeparatorClick?.(e, props);
      };
      break;
    default:
      Component = MenuItemComponent;

      // Special props for MenuItem
      assignNonUndefined(componentProps, {
        StartAdornment,
        EndAdornment
      });

      // Item click event
      componentProps.onClick = (e: React.MouseEvent) => {
        onClick?.(e as React.MouseEvent<HTMLDivElement>);
        onAnyItemClick?.(e, props);
        onItemClick?.(e, props);
      };
      break;
  }
  return <ComboMenuItemWrapComponent>
      <Component {...componentProps}>
        {content}
        {children}
      </Component>
    </ComboMenuItemWrapComponent>;
};