import React, { HtmlHTMLAttributes } from 'react';

// Styles
import s from './styles.module.css';

// Icons
import ChevronSvg from '../../assets/chevron.svg';

// Re export components
export { ChevronSvg };

// Types
export interface ChevronSharedProps {
  ChevronComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  chevronClass?: string;
}
export interface ChevronSpecificProps extends ChevronSharedProps {
  clsPrefix?: string;
}
export interface ChevronProps extends ChevronSpecificProps, HtmlHTMLAttributes<HTMLOrSVGElement> {}

// Component
export const Chevron = ({
  ChevronComponent = ChevronSvg,
  clsPrefix = 'radix-accordion',
  chevronClass = '',
  className = chevronClass,
  ...props
}: ChevronProps) => {
  return <ChevronComponent className={`${s.chevron} ${clsPrefix}-chevron-icon ${className}`} {...props} />;
};