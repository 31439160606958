import React from 'react';

// Lib Components
import { CheckboxItem as RadixCheckboxItem, DropdownMenuCheckboxItemProps as RadixCheckboxItemProps } from '@radix-ui/react-dropdown-menu';

// Components
import { ItemIndicator, ItemIndicatorSharedProps, ItemIndicatorProps } from '../ItemIndicator';

// Re export components
export { RadixCheckboxItem };

// Re export types
export type { RadixCheckboxItemProps };

// Types
export interface CheckboxItemSharedProps extends ItemIndicatorSharedProps {
  CheckboxItemComponent?: React.FC<CheckboxItemProps> | React.FC<any>;
  ItemIndicatorComponent?: React.FC<ItemIndicatorProps> | React.FC<any>;
  checkboxItemClass?: string;
}
export interface CheckboxItemSpecificProps extends CheckboxItemSharedProps {
  clsPrefix?: string;
}
export interface CheckboxItemProps extends RadixCheckboxItemProps, CheckboxItemSpecificProps {}
export const CheckboxItem = ({
  CheckboxItemComponent = RadixCheckboxItem,
  ItemIndicatorComponent = ItemIndicator,
  CheckIconComponent,
  DotIconComponent,
  itemIndicatorClass,
  checkIconClass,
  dotIconClass,
  clsPrefix = 'radix-dropdown',
  checkboxItemClass = '',
  className = checkboxItemClass,
  children,
  ...props
}: CheckboxItemProps) => {
  return <CheckboxItemComponent className={`${clsPrefix}-checkbox-item ${className}`} {...props}>
      {children}
      <ItemIndicatorComponent indicatorType="check" {...{
      CheckIconComponent,
      DotIconComponent,
      clsPrefix,
      itemIndicatorClass,
      checkIconClass,
      dotIconClass
    }} />
    </CheckboxItemComponent>;
};