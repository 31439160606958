import styled, { css } from 'styled-components';

// Components
import { Item as UIItem, Trigger as UITrigger, Content as UIContent, ItemProps } from 'shared/ui/Accordion';

// Re export types
export type { ItemProps };

// Styled components
export const Item = styled(UIItem)<ItemProps>`
  ${({
  theme
}) => css`
    color: ${theme.color.dark400};
    border-radius: ${theme.borderRadius.m} !important;
  `};

  .radix-accordion-icon {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }

  .radix-accordion-left-icon {
    margin-right: 14px;
  }
`;
export const Trigger = styled(UITrigger)`
  padding: 10px 12px;

  ${({
  theme
}) => css`
    border-radius: ${theme.borderRadius.m};

    &:hover {
      background-color: ${theme.color.grey50};
    }
  `};
`;
export const Content = styled(UIContent)`
  padding-left: 12px;
`;