import React, { HtmlHTMLAttributes } from 'react';

// Styles
import { StyledMenuItem, StyledMenuItemProps } from './styles';

// Types
export interface IconMenuItemProps extends StyledMenuItemProps {
  Icon?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
}
export const IconMenuItem = ({
  Icon,
  StartAdornment = Icon,
  content = '',
  clsPrefix = 'radix-dropdown',
  children,
  ...props
}: IconMenuItemProps) => {
  return <StyledMenuItem {...{
    StartAdornment,
    clsPrefix
  }} {...props}>
      {content}
      {children}
    </StyledMenuItem>;
};