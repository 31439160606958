import React from 'react';

// Components
import { Portal as RadixSubPortal, DropdownMenuPortalProps as RadixSubPortalProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixSubPortal };

// Re export types
export type { RadixSubPortalProps };

// Types
export interface SubPortalSharedProps {
  SubPortalComponent?: React.FC<SubPortalProps> | React.FC<any>;
}
export interface SubPortalSpecificProps extends SubPortalSharedProps {}
export interface SubPortalProps extends RadixSubPortalProps, SubPortalSpecificProps {}
export const SubPortal = ({
  SubPortalComponent = RadixSubPortal,
  ...props
}: SubPortalProps) => {
  return <SubPortalComponent {...props}>{props.children}</SubPortalComponent>;
};
export default SubPortal;