import React from 'react';

// Components
import { Sub as RadixSub, DropdownMenuSubProps as RadixSubProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixSub }; // Export the RadixSub component

// Re export types
export type { RadixSubProps };
export interface SubSharedProps {
  SubComponent?: React.FC<SubProps> | React.FC<any>;
  subClass?: string;
}
export interface SubSpecificProps extends SubSharedProps {
  clsPrefix?: string;
}
export interface SubProps extends RadixSubProps, SubSpecificProps {}
export const Sub = ({
  SubComponent = RadixSub,
  subClass = '',
  ...props
}: SubProps) => {
  return <SubComponent {...props}>{props.children}</SubComponent>;
};
export default Sub;