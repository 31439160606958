// Styles
import { StyledDropdown, StyledDropdownProps, StyledContentProps, Content, Group, Sub, SubPortal, SubContent } from './styles';

// Components
import { Separator } from './components/Separator';
import { IconMenuItem } from './components/IconMenuItem';

// Re export components
export { Sub, Group, Content, SubPortal, SubContent };
export * from './components/IconSubTrigger';
export * from './components/Separator';
export * from './components/IconMenuItem';
export * from './components/AccordionMenuItem';

// Re export types
export type { ComboMenuItemProps } from '@ui/radix-dropdown';

// Types
export interface DropdownProps extends StyledDropdownProps {}
export interface ContentProps extends StyledContentProps {}
export const Dropdown = ({
  GroupComponent = Group,
  ContentComponent = Content,
  SeparatorComponent = Separator,
  MenuItemComponent = IconMenuItem,
  ...props
}: DropdownProps) => <StyledDropdown {...{
  ContentComponent,
  MenuItemComponent,
  SeparatorComponent,
  GroupComponent
}} {...props}>
    {props.children}
  </StyledDropdown>;