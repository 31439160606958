import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { DropdownMenuLabel as RadixLabel, DropdownMenuLabelProps as RadixLabelProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixLabel };

// Re export types
export type { RadixLabelProps };

// Types
export interface LabelSharedProps {
  LabelComponent?: React.FC<LabelProps> | React.FC<any>;
  labelClass?: string;
}
export interface LabelSpecificProps extends LabelSharedProps {
  clsPrefix?: string;
}
export interface LabelProps extends RadixLabelProps, LabelSpecificProps {}
export const Label = ({
  LabelComponent = RadixLabel,
  labelClass = '',
  className = labelClass,
  clsPrefix = 'radix-dropdown',
  children,
  ...props
}: LabelProps) => {
  return <LabelComponent className={`${s.label} ${clsPrefix}-label ${className}`} {...props}>
      {children}
    </LabelComponent>;
};
export default Label;