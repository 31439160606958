import React, { HtmlHTMLAttributes } from 'react';

// Components
import { ComboMenuItem, ComboMenuItemProps, ComboMenuItemSharedProps } from '../ComboMenuItem';
import { Group, GroupSharedProps, GroupProps } from '../Group';
import { RadioGroup, RadioGroupSharedProps, RadioGroupProps } from '../RadioGroup';
import { Separator } from '../Separator';
import { Sub, SubSharedProps } from '../Sub';
import { SubTrigger, SubTriggerSharedProps, SubTriggerProps } from '../SubTrigger';
import { SubPortal, SubPortalSharedProps } from '../SubPortal';
import { SubContent, SubContentSharedProps } from '../SubContent';

// Types
export interface ComboMenuItemsSharedProps extends ComboMenuItemSharedProps, SubPortalSharedProps, GroupSharedProps, RadioGroupSharedProps, SubContentSharedProps, SubTriggerSharedProps, SubSharedProps {
  ComboMenuItemsWrapComponent?: React.FC<HtmlHTMLAttributes<HTMLDivElement>> | React.FC<any>;
  ComboMenuItemComponent?: React.FC<ComboMenuItemProps> | React.FC<any>;
  clsPrefix?: string;
  comboMenuItemsWrapClass?: string;
  items?: ComboMenuItemProps[];
}
export interface ComboMenuItemsSpecificProps extends ComboMenuItemsSharedProps {}
export interface ComboMenuItemsProps extends ComboMenuItemsSpecificProps, HtmlHTMLAttributes<HTMLDivElement> {
  disableItems?: boolean;
}
const Stub = (p: any) => <>{p.children}</>;
export const ComboMenuItems = (props: ComboMenuItemsProps) => {
  const {
    ComboMenuItemsWrapComponent = Stub,
    ComboMenuItemComponent = ComboMenuItem,
    GroupComponent = Group,
    RadioGroupComponent = RadioGroup,
    SubComponent = Sub,
    SubTriggerComponent = SubTrigger,
    SubPortalComponent = SubPortal,
    SubContentComponent = SubContent,
    SeparatorComponent = Separator,
    ComboMenuItemWrapComponent,
    LabelComponent,
    MenuItemComponent,
    CheckboxItemComponent,
    RadioItemComponent,
    ItemIndicatorComponent,
    DotIconComponent,
    CheckIconComponent,
    StartAdornment,
    EndAdornment,
    comboMenuItemsWrapClass = '',
    groupClass,
    radioGroupClass,
    subContentClass,
    subTriggerClass,
    labelClass,
    dotIconClass,
    menuItemClass,
    radioItemClass,
    separatorClass,
    checkIconClass,
    checkboxItemClass,
    itemIndicatorClass,
    menuItemAdornmentClass,
    menuItemEndAdornmentClass,
    menuItemStartAdornmentClass,
    items = [],
    clsPrefix = 'radix-dropdown',
    className = comboMenuItemsWrapClass,
    children,
    disableItems = false,
    onAnyItemClick,
    onItemClick,
    onLabelClick,
    onCheckboxClick,
    onRadioClick,
    onSeparatorClick
  } = props;
  return <ComboMenuItemsWrapComponent className={`${clsPrefix}-items-wrap ${className}`} {...props}>
      {children}
      {!!items?.length && items.map((item: Partial<ComboMenuItemProps> = {}, i) => {
      const {
        id,
        type = 'item'
      } = item;
      if (type === 'sub') {
        const {
          items = [],
          StartAdornment,
          EndAdornment,
          ...subTriggerProps
        } = item;
        return <SubComponent key={id || i}>
                <SubTriggerComponent {...{
            clsPrefix,
            subTriggerClass
          }} {...subTriggerProps as SubTriggerProps}>
                  {subTriggerProps.content}
                  {subTriggerProps.children}
                </SubTriggerComponent>
                <SubPortalComponent>
                  <SubContentComponent {...{
              clsPrefix,
              subContentClass
            }}>
                    <ComboMenuItems {...props} items={items} />
                  </SubContentComponent>
                </SubPortalComponent>
              </SubComponent>;
      }
      if (type === 'group') {
        const {
          items = [],
          ...groupProps
        } = item;
        return <GroupComponent {...{
          clsPrefix,
          groupClass
        }} {...groupProps as GroupProps}>
                <ComboMenuItems {...props} items={items} />
              </GroupComponent>;
      }
      if (type === 'radio-group') {
        const {
          items = [],
          ...radioGroupProps
        } = item;
        return <RadioGroupComponent {...{
          clsPrefix,
          radioGroupClass
        }} {...radioGroupProps as RadioGroupProps}>
                <ComboMenuItems {...props} items={items} />
              </RadioGroupComponent>;
      }

      // @ts-ignore
      return <ComboMenuItemComponent key={id || i} {...{
        clsPrefix
      }} disabled={disableItems} {...{
        ComboMenuItemWrapComponent,
        LabelComponent,
        MenuItemComponent,
        CheckboxItemComponent,
        RadioItemComponent,
        ItemIndicatorComponent,
        SeparatorComponent,
        CheckIconComponent,
        DotIconComponent,
        StartAdornment,
        EndAdornment,
        labelClass,
        menuItemClass,
        radioItemClass,
        checkboxItemClass,
        itemIndicatorClass,
        checkIconClass,
        dotIconClass,
        separatorClass,
        menuItemAdornmentClass,
        menuItemStartAdornmentClass,
        menuItemEndAdornmentClass,
        onAnyItemClick,
        onItemClick,
        onLabelClick,
        onCheckboxClick,
        onRadioClick,
        onSeparatorClick
      }} {...item} />;
    })}
    </ComboMenuItemsWrapComponent>;
};