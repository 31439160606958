import React from 'react';

// Styles
import s from './styles.module.css';

// Lib Components
import { ItemIndicator as RadixItemIndicator, DropdownMenuItemIndicatorProps as RadixItemIndicatorProps } from '@radix-ui/react-dropdown-menu';

// Components
import { CheckIcon, CheckIconSharedProps } from '../CheckIcon';
import { DotIcon, DotIconSharedProps } from '../DotIcon';

// Re export components
export { RadixItemIndicator };

// Re export types
export type { RadixItemIndicatorProps };

// Types
export interface ItemIndicatorSharedProps extends CheckIconSharedProps, DotIconSharedProps {
  ItemIndicatorComponent?: React.FC<RadixItemIndicatorProps> | React.FC<any>;
  itemIndicatorClass?: string;
}
export interface ItemIndicatorSpecificProps extends ItemIndicatorSharedProps {
  clsPrefix?: string;
  indicatorType?: 'check' | 'dot' | 'none';
}
export interface ItemIndicatorProps extends RadixItemIndicatorProps, ItemIndicatorSpecificProps {}
export const ItemIndicator = ({
  ItemIndicatorComponent = RadixItemIndicator,
  CheckIconComponent = CheckIcon,
  DotIconComponent = DotIcon,
  checkIconClass = '',
  dotIconClass = '',
  clsPrefix = 'radix-dropdown',
  itemIndicatorClass = '',
  className = itemIndicatorClass,
  indicatorType = 'check',
  children,
  ...props
}: ItemIndicatorProps) => {
  return <ItemIndicatorComponent className={`${s.itemIndicator} ${clsPrefix}-item-indicator ${className}`} {...props}>
      {indicatorType === 'check' && <CheckIconComponent className={`${clsPrefix}-item-icon ${clsPrefix}-item-check-icon ${checkIconClass}`} />}
      {indicatorType === 'dot' && <DotIconComponent className={`${clsPrefix}-item-icon ${clsPrefix}-item-dot-icon ${dotIconClass}`} />}
      {children}
    </ItemIndicatorComponent>;
};
export default ItemIndicator;