import { styled, css } from 'styled-components';

// Components
import { Separator as BaseSeparator, SeparatorProps as BaseSeparatorProps } from '@ui/radix-dropdown';

// Types
export interface SeparatorProps extends BaseSeparatorProps {}
export const Separator = styled(BaseSeparator)<SeparatorProps>`
  ${({
  theme
}) => css`
    margin: 12px 0;
    background-color: ${theme?.['color']?.grey100};
  `};
`;