import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { DropdownMenuSeparator as RadixSeparator, DropdownMenuSeparatorProps as RadixSeparatorProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixSeparator };

// Re export types
export type { RadixSeparatorProps };

// Types
export interface SeparatorSharedProps {
  SeparatorComponent?: React.FC<SeparatorProps> | React.FC<any>;
  separatorClass?: string;
}
export interface SeparatorSpecificProps extends SeparatorSharedProps {
  clsPrefix?: string;
}
export interface SeparatorProps extends RadixSeparatorProps, SeparatorSpecificProps {}
export const Separator = ({
  SeparatorComponent = RadixSeparator,
  separatorClass = '',
  className = separatorClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: SeparatorProps) => {
  return <SeparatorComponent className={`${s.separator} ${clsPrefix}-separator ${className}`} {...props}>
      {props.children}
    </SeparatorComponent>;
};