export const SHP_LOGO_COLORS = ['#000000', '#34e5b5', '#f53ca5', '#fbf217', '#a020db'];
export const SHP_CATEGORY_COLORS = {
  A: '#005500',
  B: '#e67e00',
  C: '#8b0000',
  V: '#00008b'
};
export const SHP_WEBSITE_NAME = 'MY.BUCHEGG.SOCIAL';
export const SHP_LOGO_COLOR = SHP_LOGO_COLORS[0];
export const COMMON_DATE_FORMAT = 'DD.MM.YYYY';
export const COMMON_FULL_DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const isDev = process?.env?.NODE_ENV === 'development' || process?.env?.NEXT_PUBLIC_APP_ENV === 'development';