import React from 'react';

// Components
import { Root, RootSharedProps } from '../components/Root';
import { Trigger, TriggerSharedProps, TriggerProps } from '../components/Trigger';
import { Content, ContentSharedProps, ContentProps } from '../components/Content';
import { Item, ItemSharedProps, ItemProps } from '../components/Item';

// Re export components
export * from '../components/Root';
export * from '../components/Trigger';
export * from '../components/Content';
export * from '../components/Item';
export * from '../components/Chevron';

// Types
export interface AccordionItemProps extends Omit<ItemProps, 'content'> {
  label?: React.ReactNode;
  content?: React.ReactNode;
  triggerProps?: TriggerProps;
  contentProps?: ContentProps;
}
export interface AccordionSpecificProps extends RootSharedProps, TriggerSharedProps, ContentSharedProps, ItemSharedProps {
  items?: AccordionItemProps[];
  clsPrefix?: string;
}
export interface AccordionProps extends AccordionSpecificProps {}

// Component
export const Accordion = ({
  RootComponent = Root,
  TriggerComponent = Trigger,
  ContentComponent = Content,
  ItemComponent = Item,
  triggerClass,
  contentClass,
  itemClass,
  type = 'single',
  items = [],
  clsPrefix = 'radix-accordion',
  className = '',
  showIcon = true,
  children,
  ...props
}: AccordionProps) => {
  return <RootComponent {...{
    type,
    clsPrefix,
    className
  }} {...props}>
      {children}
      {!!items?.length && items.map((item, index) => {
      const {
        label = `Item ${index + 1}`,
        triggerProps = {},
        contentProps = {},
        children,
        content = '',
        value = String(index),
        ...itemProps
      } = item;
      return <ItemComponent key={index} value={value} {...{
        clsPrefix,
        itemClass
      }} {...itemProps}>
              <TriggerComponent {...{
          clsPrefix,
          triggerClass,
          showIcon
        }} {...triggerProps}>
                {label}
                {children}
                {triggerProps.children}
              </TriggerComponent>

              <ContentComponent {...{
          clsPrefix,
          contentClass
        }} {...contentProps}>
                {content}
                {contentProps.children}
              </ContentComponent>
            </ItemComponent>;
    })}
    </RootComponent>;
};