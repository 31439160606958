import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { DropdownMenuArrow as RadixArrow, DropdownMenuArrowProps as RadixArrowProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixArrow };

// Re export types
export type { RadixArrowProps };

// Types
export interface ArrowSharedProps {
  ArrowComponent?: React.FC<ArrowProps> | React.FC<any>;
  arrowClass?: string;
}
export interface ArrowSpecificProps extends ArrowSharedProps {
  clsPrefix?: string;
}
export interface ArrowProps extends RadixArrowProps, ArrowSpecificProps {}
export const Arrow = ({
  ArrowComponent = RadixArrow,
  arrowClass = '',
  className = arrowClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: ArrowProps) => {
  return <ArrowComponent className={`${s.arrow} ${clsPrefix}-arrow ${className}`} {...props}>
      {props.children}
    </ArrowComponent>;
};
export default Arrow;