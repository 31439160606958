import { styled, css } from 'styled-components';

// Components
import { SubTrigger, SubTriggerProps } from '@ui/radix-dropdown';

// Types
export interface StyledSubTriggerProps extends SubTriggerProps {}

// Styled components
export const StyledSubTrigger = styled(SubTrigger)<StyledSubTriggerProps>`
  ${({
  theme
}) => css`
    color: ${theme?.['color']?.dark400};
    font-size: ${theme?.['fontSize']?.m};
    border-radius: ${theme?.['borderRadius']?.m};
    padding: 10px 12px;

    &:hover,
    &[data-open='true'] {
      color: ${theme?.['color']?.dark500};
      background-color: ${theme?.['color']?.grey50};
    }

    &[data-disabled] {
      opacity: 0.5;
    }

    .radix-dropdown-sub-trigger-adornment {
      width: 20px;
      max-height: 20px;
    }
    .radix-dropdown-sub-trigger-start-adornment {
      margin-right: 14px;
    }
    .radix-dropdown-sub-trigger-end-adornment {
      margin-left: 14px;
    }
  `};
`;