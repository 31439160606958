import React, { HtmlHTMLAttributes } from 'react';

// Icons
import DotSvg from '../../assets/dot.svg';

// Types
export interface DotIconSharedProps {
  DotIconComponent?: React.FC<DotIconProps> | React.FC<any>;
  dotIconClass?: string;
}
export interface DotIconSpecificProps extends DotIconSharedProps {
  clsPrefix?: string;
}
export interface DotIconProps extends DotIconSpecificProps, HtmlHTMLAttributes<SVGElement> {}

// Styled components
export const DotIcon = ({
  clsPrefix = 'radix-dropdown',
  dotIconClass = '',
  className = dotIconClass,
  ...props
}: DotIconProps) => {
  return <DotSvg className={`${clsPrefix}-dot-icon ${className}`} {...props} />;
};