import React from 'react';

// Components
import { DropdownMenuGroup as RadixGroup, DropdownMenuGroupProps as RadixGroupProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixGroup };

// Re export types
export type { RadixGroupProps };

// Types
export interface GroupSharedProps {
  GroupComponent?: React.FC<GroupProps> | React.FC<any>;
  groupClass?: string;
}
export interface GroupSpecificProps extends GroupSharedProps {
  clsPrefix?: string;
}
export interface GroupProps extends RadixGroupProps, GroupSpecificProps {}
export const Group = ({
  GroupComponent = RadixGroup,
  groupClass = '',
  className = groupClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: GroupProps) => {
  return <GroupComponent className={`${clsPrefix}-group ${className}`} {...props} />;
};