import React from 'react';

// Components
import { Root as RadixRoot, DropdownMenuProps as RadixRootProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixRoot };

// Re export types
export type { RadixRootProps };

// Types
export interface RootSharedProps {
  RootComponent?: React.FC<RootProps> | React.FC<any>;
}
export interface RootSpecificProps extends RootSharedProps {}
export interface RootProps extends RadixRootProps, RootSpecificProps {}
export const Root = ({
  RootComponent = RadixRoot,
  ...props
}: RootProps) => {
  return <RootComponent {...props}>{props.children}</RootComponent>;
};