import React from 'react';

// Components
import { Portal as RadixPortal, DropdownMenuPortalProps as RadixPortalProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixPortal };

// Re export types
export type { RadixPortalProps };

// Types
export interface PortalSharedProps {
  PortalComponent?: React.FC<PortalProps> | React.FC<any>;
}
export interface PortalSpecificProps extends PortalSharedProps {}
export interface PortalProps extends RadixPortalProps, PortalSpecificProps {}
export const Portal = ({
  PortalComponent = RadixPortal,
  ...props
}: PortalProps) => {
  return <PortalComponent {...props}>{props.children}</PortalComponent>;
};
export default Portal;