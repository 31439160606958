import React from 'react';

// Styles
import sT from '../Trigger/styles.module.css';
import s from './styles.module.css';

// Components
import { SubTrigger as RadixSubTrigger, DropdownMenuSubTriggerProps as RadixSubTriggerProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixSubTrigger };

// Re export types
export type { RadixSubTriggerProps };

// Types
export interface SubTriggerSharedProps {
  SubTriggerComponent?: React.FC<SubTriggerProps> | React.FC<any>;
  StartAdornment?: React.FC<any>;
  EndAdornment?: React.FC<any>;
  subTriggerClass?: string;
}
export interface SubTriggerSpecificProps extends SubTriggerSharedProps {
  clsPrefix?: string;
}
export interface SubTriggerProps extends RadixSubTriggerProps, SubTriggerSpecificProps {}
const AdornmentStub = () => <></>;
export const SubTrigger = ({
  SubTriggerComponent = RadixSubTrigger,
  StartAdornment = AdornmentStub,
  EndAdornment = AdornmentStub,
  subTriggerClass = '',
  className = subTriggerClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: SubTriggerProps) => {
  return <SubTriggerComponent className={`${sT.trigger} ${s.subTrigger} ${clsPrefix}-sub-trigger ${className}`} {...props}>
      <StartAdornment className={`${clsPrefix}-sub-trigger-adornment ${clsPrefix}-sub-trigger-start-adornment`} />
      {props.children}
      <EndAdornment className={`${clsPrefix}-sub-trigger-adornment ${clsPrefix}-sub-trigger-end-adornment`} />
    </SubTriggerComponent>;
};
export default SubTrigger;