import React from 'react';

// Components
import { Root as RadixRoot } from '@radix-ui/react-accordion';

// Re export components
export { RadixRoot };

// Types
export interface RootSharedProps {
  RootComponent?: React.FC<RootProps> | React.FC<any>;
  className?: string;
  type?: 'single' | 'multiple';
  value?: Array<string> | string;
  defaultValue?: Array<string> | string;
  onValueChange?: (value: Array<string> | string) => void;
  disabled?: boolean;
  collapsible?: boolean;
  children?: React.ReactNode;
}
export interface RootSpecificProps extends RootSharedProps {
  clsPrefix?: string;
}
export interface RootProps extends RootSpecificProps {}
export const Root = ({
  RootComponent = RadixRoot,
  clsPrefix = 'radix-accordion',
  className = '',
  children,
  ...props
}: RootProps) => {
  return <RootComponent className={`${clsPrefix} ${className}`} {...props}>
      {children}
    </RootComponent>;
};