import React, { forwardRef } from 'react';

// Styles
import s from '../Content/styles.module.css';

// Components
import { SubContent as RadixSubContent, DropdownMenuSubContentProps as RadixSubContentProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixSubContent };

// Re export types
export type { RadixSubContentProps };

// Types
export interface SubContentSharedProps {
  SubContentComponent?: React.FC<SubContentProps> | React.FC<any>;
  subContentClass?: string;
}
export interface SubContentSpecificProps extends SubContentSharedProps {
  clsPrefix?: string;
}
export interface SubContentProps extends RadixSubContentProps, SubContentSpecificProps {
  ref?: React.Ref<HTMLDivElement>;
}
export const SubContent = forwardRef<HTMLDivElement, SubContentProps>(({
  SubContentComponent = RadixSubContent,
  subContentClass = '',
  className = subContentClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: SubContentProps, ref) => {
  return <SubContentComponent avoidCollisions className={`${s.content} ${clsPrefix}-sub-content ${className}`} ref={ref} {...props}>
        {props.children}
      </SubContentComponent>;
});