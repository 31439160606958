import React from 'react';

// Components
import { DropdownMenuRadioGroup as RadixRadioGroup, DropdownMenuRadioGroupProps as RadixRadioGroupProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixRadioGroup };

// Re export types
export type { RadixRadioGroupProps };

// Types
export interface RadioGroupSharedProps {
  RadioGroupComponent?: React.FC<RadioGroupProps> | React.FC<any>;
  radioGroupClass?: string;
}
export interface RadioGroupSpecificProps extends RadioGroupSharedProps {
  clsPrefix?: string;
}
export interface RadioGroupProps extends RadixRadioGroupProps, RadioGroupSpecificProps {}
export const RadioGroup = ({
  RadioGroupComponent = RadixRadioGroup,
  radioGroupClass = '',
  className = radioGroupClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: RadioGroupProps) => {
  return <RadioGroupComponent className={`${clsPrefix}-radio-group ${className}`} {...props}>
      {props.children}
    </RadioGroupComponent>;
};