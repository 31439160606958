import React from 'react';

// Lib Components
import { DropdownMenuRadioItem as RadixRadioItem, DropdownMenuRadioItemProps as RadixRadioItemProps } from '@radix-ui/react-dropdown-menu';

// Components
import { ItemIndicator, ItemIndicatorSharedProps, ItemIndicatorProps } from '../ItemIndicator';

// Re export components
export { RadixRadioItem };

// Re export types
export type { RadixRadioItemProps };

// Types
export interface RadioItemSharedProps extends ItemIndicatorSharedProps {
  RadioItemComponent?: React.FC<RadioItemProps> | React.FC<any>;
  ItemIndicatorComponent?: React.FC<ItemIndicatorProps> | React.FC<any>;
  radioItemClass?: string;
}
export interface RadioItemSpecificProps extends RadioItemSharedProps {
  clsPrefix?: string;
}
export interface RadioItemProps extends RadixRadioItemProps, RadioItemSpecificProps {}
export const RadioItem = ({
  RadioItemComponent = RadixRadioItem,
  ItemIndicatorComponent = ItemIndicator,
  CheckIconComponent,
  DotIconComponent,
  itemIndicatorClass,
  checkIconClass,
  dotIconClass,
  clsPrefix = 'radix-dropdown',
  radioItemClass = '',
  className = radioItemClass,
  children,
  ...props
}: RadioItemProps) => {
  return <RadioItemComponent className={`${clsPrefix}-radio-item ${className}`} {...props}>
      {children}
      <ItemIndicatorComponent indicatorType="dot" {...{
      CheckIconComponent,
      DotIconComponent,
      clsPrefix,
      itemIndicatorClass,
      checkIconClass,
      dotIconClass
    }} />
    </RadioItemComponent>;
};
export default RadioItem;