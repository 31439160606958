import React from 'react';
import { styled, css } from 'styled-components';

// Components
import { Group as ParentGroup, Content as ParentContent, GroupProps as ParentGroupProps, DropdownMenu as ParentDropdownMenu, ContentProps as ParentContentProps, Sub, SubPortal, SubContent as ParentSubContent, SubPortalProps, SubContentProps, ComboMenuItemProps, DropdownMenuProps as ParentDropdownMenuProps } from '@ui/radix-dropdown';

// Re export components
export { SubPortal, Sub };

// Re export types
export type { ComboMenuItemProps, SubPortalProps, SubContentProps };

// Types
export interface StyledDropdownProps extends Partial<ParentDropdownMenuProps> {}
export interface StyledContentProps extends ParentContentProps {}
export interface StyledGroupProps extends ParentGroupProps {}

// Styled components
export const Content: React.FC<StyledContentProps> = styled(ParentContent)<StyledContentProps>`
  ${({
  theme
}) => css`
    padding: 12px;
    border-radius: ${theme?.['borderRadius']?.m};
  `};
`;
export const SubContent = styled(ParentSubContent)<SubContentProps>`
  ${({
  theme
}) => css`
    padding: 12px;
    border-radius: ${theme?.['borderRadius']?.m};
  `};
`;
export const Group: React.FC<StyledGroupProps> = ParentGroup;
export const StyledDropdown: React.FC<StyledDropdownProps> = ParentDropdownMenu;