import React, { forwardRef } from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Content as RadixContent, DropdownMenuContentProps as RadixContentProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixContent };

// Re export types
export type { RadixContentProps };

// Types
export interface ContentSharedProps {
  ContentComponent?: React.FC<ContentProps> | React.FC<any>;
  contentClass?: string;
}
export interface ContentSpecificProps extends ContentSharedProps {
  clsPrefix?: string;
}
export interface ContentProps extends RadixContentProps, ContentSpecificProps {}
export const Content = forwardRef<HTMLDivElement, ContentProps>(({
  ContentComponent = RadixContent,
  contentClass = '',
  className = contentClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: ContentProps, ref) => {
  return <ContentComponent avoidCollisions className={`${s.content} ${clsPrefix}-content ${className}`} ref={ref} {...props}>
        {props.children}
      </ContentComponent>;
});
export default Content;