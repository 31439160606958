import React, { HtmlHTMLAttributes } from 'react';

// Icons
import CheckSvg from '../../assets/check.svg';

// Types
export interface CheckIconSharedProps {
  CheckIconComponent?: React.FC<CheckIconProps> | React.FC<any>;
  checkIconClass?: string;
}
export interface CheckIconSpecificProps extends CheckIconSharedProps {
  clsPrefix?: string;
}
export interface CheckIconProps extends CheckIconSpecificProps, HtmlHTMLAttributes<SVGElement> {}

// Styled components
export const CheckIcon = ({
  clsPrefix = 'radix-dropdown',
  checkIconClass = '',
  className = checkIconClass,
  ...props
}: CheckIconProps) => {
  return <CheckSvg className={`${clsPrefix}-check-icon ${className}`} {...props} />;
};