import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Item as RadixItem, AccordionItemProps as RadixItemProps } from '@radix-ui/react-accordion';

// Re export components
export { RadixItem };

// Re export types
export type { RadixItemProps };

// Types
export interface ItemSharedProps {
  ItemComponent?: React.FC<ItemProps> | React.FC<any>;
  itemClass?: string;
}
export interface ItemSpecificProps extends ItemSharedProps {
  clsPrefix?: string;
}
export interface ItemProps extends ItemSpecificProps, RadixItemProps {}

// Component
export const Item = ({
  ItemComponent = RadixItem,
  clsPrefix = 'radix-accordion',
  itemClass = '',
  className = itemClass,
  children,
  ...props
}: ItemProps) => {
  return <ItemComponent className={`${s.item} ${clsPrefix}-item ${className}`} {...props}>
      {children}
    </ItemComponent>;
};