import React, { HtmlHTMLAttributes } from 'react';

// Styles
import { StyledSubTrigger, StyledSubTriggerProps } from './styles';

// Types
export interface IconSubTriggerProps extends StyledSubTriggerProps {
  Icon?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
}
export const IconSubTrigger = ({
  Icon,
  StartAdornment = Icon,
  content = '',
  clsPrefix = 'radix-dropdown',
  children,
  ...props
}: IconSubTriggerProps) => {
  return <StyledSubTrigger {...{
    StartAdornment,
    clsPrefix
  }} {...props}>
      {content}
      {children}
    </StyledSubTrigger>;
};