// Styles
import { Item, Trigger, Content } from './styles';

// Components
import { Accordion, AccordionProps } from 'shared/ui/Accordion';

// Types
export interface AccordionMenuItemProps extends AccordionProps {
  label?: React.ReactNode;
}
export const AccordionMenuItem = ({
  LeftIcon,
  RightIcon,
  label = '',
  ...props
}: AccordionMenuItemProps) => {
  return <Accordion collapsible {...props}>
      <Item value={label as string}>
        <Trigger {...{
        LeftIcon,
        RightIcon
      }}>{label}</Trigger>
        <Content>{props.children}</Content>
      </Item>
    </Accordion>;
};