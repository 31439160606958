export const assignNonUndefined = (
  target: Record<any, any>,
  ...sources: Record<any, any>[]
): object => {
  for (const source of sources) {
    for (const key in source) {
      if (
        Object.prototype.hasOwnProperty.call(source, key) &&
        source[key] !== undefined
      ) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
