import React from 'react';

// Styles
import s from './styles.module.css';
import sC from '../Chevron/styles.module.css';

// Components
import { span } from '@ui/base-html-tags';
import { Trigger as RadixTrigger, AccordionTriggerProps as RadixTriggerProps } from '@radix-ui/react-accordion';
import { Chevron, ChevronProps } from '../Chevron';

// Re export components
export { RadixTrigger };

// Re export types
export type { RadixTriggerProps };

// Types
export interface TriggerSharedProps {
  TriggerComponent?: React.FC<TriggerProps> | React.FC<any>;
  TriggerContentComponent?: React.FC<any>;
  LeftIcon?: React.FC<any>;
  RightIcon?: React.FC<ChevronProps> | React.FC<any>;
  triggerClass?: string;
  showIcon?: boolean;
  showLeftIcon?: boolean;
  showRightIcon?: boolean;
}
export interface TriggerSpecificProps extends TriggerSharedProps {
  clsPrefix?: string;
}
export interface TriggerProps extends TriggerSpecificProps, RadixTriggerProps {}
const Stub = () => <></>;

// Component
export const Trigger = ({
  TriggerComponent = RadixTrigger,
  TriggerContentComponent = span,
  LeftIcon = Stub,
  RightIcon = Chevron,
  clsPrefix = 'radix-accordion',
  triggerClass = '',
  className = triggerClass,
  showIcon = true,
  showLeftIcon = showIcon,
  showRightIcon = showIcon,
  children,
  ...props
}: TriggerProps) => {
  return <TriggerComponent className={`${s.trigger} ${sC.chevronTrigger} ${clsPrefix}-trigger  ${className}`} {...props}>
      {showLeftIcon && <LeftIcon className={`${clsPrefix}-icon ${clsPrefix}-left-icon`} />}
      <TriggerContentComponent className={`${s.triggerContent} ${clsPrefix}-trigger-content`}>
        {children}
      </TriggerContentComponent>
      {showRightIcon && <RightIcon className={`${clsPrefix}-icon ${clsPrefix}-right-icon`} />}
    </TriggerComponent>;
};