import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Trigger as RadixTrigger, DropdownMenuTriggerProps as RadixTriggerProps } from '@radix-ui/react-dropdown-menu';

// Re export components
export { RadixTrigger };

// Re export types
export type { RadixTriggerProps };

// Types
export interface TriggerSharedProps {
  TriggerComponent?: React.FC<TriggerProps> | React.FC<any>;
  triggerClass?: string;
}
export interface TriggerSpecificProps extends TriggerSharedProps {
  clsPrefix?: string;
}
export interface TriggerProps extends RadixTriggerProps, TriggerSpecificProps {}
export const Trigger = ({
  TriggerComponent = RadixTrigger,
  triggerClass = '',
  className = triggerClass,
  clsPrefix = 'radix-dropdown',
  ...props
}: TriggerProps) => {
  return <TriggerComponent className={`${s.trigger} ${clsPrefix}-trigger ${className}`} {...props}>
      {props.children}
    </TriggerComponent>;
};
export default Trigger;